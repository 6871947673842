import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Logo from "~/images/logo.png"

const Header = ({ setOpenModal, logoUseH1 }) => {
  return (
    <header className="p-8">
      { logoUseH1 ? (
        <h1 className="flex flex-col items-center">
          <Link className="mx-2 w-48 text-lg relative" to="/">
            <span className="absolute inline-block" style={{textIndent: "-9999px"}}>健康の选</span><img src={Logo} alt="健康の选" width="377" height="123" />
          </Link>
        </h1>
      ) : (
        <div className="flex flex-col items-center">
          <Link className="mx-2 w-48 text-lg" to="/">
            <img src={Logo} alt="健康の选" width="377" height="123" />
          </Link>
          {/*<div className="flex flex-col items-center md:flex-row">
            <Link className="mx-2 mb-2 md:mb-0 text-lg" to="/">
              Categories
            </Link>
            <Link className="mx-2 mb-2 md:mb-0 text-lg" to="/products">
              Products
            </Link>
            <button
              className="mx-2 mb-2 md:mb-0 text-lg"
              onClick={() => setOpenModal(true)}
            >
              Search
            </button>
          </div>*/}
        </div>
      )}
      {/* <hr className="mt-6 m-auto w-24 border-t-4" /> */}
    </header>
  )
}

Header.propTypes = {
  siteName: PropTypes.string,
  logoUseH1: PropTypes.bool
}

Header.defaultProps = {
  siteName: ``,
  logoUseH1: false
}

export default Header
