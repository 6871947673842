import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const sitePublicUrl = process.env.GATSBY_PUBLIC_URL || "http://localhost:3000"
const showScript = (process.env.NODE_ENV == 'production')

const SEO = ({ seo = {} }) => {

  const query = graphql`
    query {
      strapiGlobal {
        siteName
        favicon {
          publicURL
        }
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            publicURL
          }
        }
      }
    }
  `
  const { strapiGlobal } = useStaticQuery(query)
  const { defaultSeo, siteName, favicon } = strapiGlobal

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo }

  const newlineToSpace = (str) => {
    return str.replace(/\n/g, ' ')
  }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.description) {
      fullSeo.metaDescription = newlineToSpace(fullSeo.description)
    }

    if (fullSeo.title) {
      fullSeo.metaTitle = `${fullSeo.title} | ${fullSeo.metaTitle}`
    }

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      )
    }

    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      )
    }

    if (fullSeo.shareImage) {
      const imageUrl = sitePublicUrl + fullSeo.shareImage.publicURL
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      )
    }

    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      })
    }

    tags.push({ name: "twitter:card", content: "summary_large_image" })

    fullSeo.metaTags && fullSeo.metaTags.map(item => tags.push(item))
    
    return tags
  }

  const metaTags = getMetaTags()

  return (
    <Helmet
      htmlAttributes={{
        lang: 'zh',
      }}
      title={fullSeo.title || fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      meta={metaTags}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL,
        },
      ]}
    >
      {fullSeo.ldJson}

      {showScript &&  
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-G6WF3JWBSM"></script>
      }
      {showScript &&  
        <script>{`window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','G-G6WF3JWBSM');`}</script>
      }  
      {showScript &&              
        <script>{`(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window,document,"clarity","script","620vez08wg");`}</script>
      }
      {showScript &&    
        <script>{`var _hmt=_hmt||[];(function(){var hm=document.createElement("script");hm.src="//hm.baidu.com/hm.js?f4af7f4bdc064f755cbca8bd22c5efa7";var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(hm,s);})();`}</script>
      }
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  ldJson: PropTypes.string,
  metaTags: PropTypes.object
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  ldJson: null,
  metaTags: null
}

export default SEO
