import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import SearchResults from "~/components/search-results"
import Footer from "~/components/footer"

import Header from "~/components/header"

import GoogleCustomSearch from "~/components/google-search"

const Layout = ({ children, logoUseH1, location }) => {
  const data = useStaticQuery(graphql`
    query SiteNameQuery {
      strapiGlobal {
        siteName
      }
    }
  `)

  const [openModal, setOpenModal] = useState(false)

  return (
    <div className="bg-gray-50 relative">
      <Header
        logoUseH1={logoUseH1}
        setOpenModal={setOpenModal}
        siteName={data.strapiGlobal.siteName || `Strapi`}
      />
      <div className="flex flex-col max-w-screen-lg m-auto min-h-screen px-6 py-0 md:px-10 md:py-0">
        <GoogleCustomSearch />
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
      {/* {openModal && (
        <div className="h-screen max-w-screen-lg m-auto fixed bottom-0 top-0 right-0 left-0 px-6 pb-10 pt-20 md:p-10 md:py-0 md:pt-40">
          <SearchResults setOpenModal={setOpenModal} openModal={openModal} />
        </div>
      )} */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logoUseH1: PropTypes.bool
}

Layout.defaultProps = {
  logoUseH1: false
}

export default Layout
