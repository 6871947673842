import React from "react"

class GoogleCustomSearch extends React.Component {
  componentDidMount() {
    (function() {
      var cx = '1915c825006a0a349';
      var gcse = document.createElement('script');
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();
  }

  render() {
    return (
      <div className="gcse-search"></div>
    )
  }
}

export default GoogleCustomSearch
